import React, { useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import { useIntl, defineMessages } from 'react-intl-next';

import { Flex } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useExpandSideNav } from '@atlassian/navigation-system/layout/side-nav';

import { OnboardingNudge } from '@confluence/onboarding-nudge-tooltip';
import { useOnboardingState } from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { HOME } from '@confluence/named-routes';
import { ErrorDisplay } from '@confluence/error-boundary';
import { useChoreographerAssets } from '@confluence/choreographer-services';

import { HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS } from './homeOnboardingTakeoverV2OnboardingStateKeys';
import { useIsEligibleForHomeOnboardingTakeoverV2HomeNudge } from './hooks/useIsEligibleForHomeOnboardingTakeoverV2HomeNudge';

//TODO before merge add descriptions
const i18n = defineMessages({
	dismissCTA: {
		id: 'experiment-home-onboarding-takeover-v2.home-nudge.dismiss-cta',
		defaultMessage: 'Dismiss',
		description:
			'This shown as a button on a spotlight card. Clicking this button will dismiss the spotlight',
	},
	goHomeCTA: {
		id: 'experiment-home-onboarding-takeover-v2.home-nudge.go-home-cta.',
		defaultMessage: 'Go Home',
		description:
			'This shown as a button on a spotlight card. Clicking this button redirect the user back to the home screen',
	},
	description: {
		id: 'experiment-home-onboarding-takeover-v2.home-nudge.description',
		defaultMessage: 'When you’re ready to continue onboarding, head back Home.',
		description:
			'This shown as a description on a spotlight card. `Home`in this sentence refers to the home screen',
	},
});

const NudgeFixer: FC = ({ children }) => {
	return (
		<Flex direction="column" justifyContent="center">
			{children}
		</Flex>
	);
};

type HomeOnboardingTakeoverV2HomeNudgeProps = {
	position?: 'bottom-start' | 'right-start';
};

export const HomeOnboardingTakeoverV2HomeNudge: FC<HomeOnboardingTakeoverV2HomeNudgeProps> = ({
	children,
	position = 'bottom-start',
}) => {
	const [isHomeNudgeHidden, setIsHomeNudgeHidden] = useState(true);
	const { formatMessage } = useIntl();
	const { setOnboardingState } = useOnboardingState();

	const { push } = useRouteActions();
	// This is the onboarding state hidden
	const { isEligibleForHomeNudge, isOnHome, error } =
		useIsEligibleForHomeOnboardingTakeoverV2HomeNudge();
	const wasEligibleForOnboardingNudgeRef = useRef(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { useChoreographer } = useChoreographerAssets();
	const expandSideNav = useExpandSideNav();

	const homeNudgeChoreographer = useChoreographer('home-onboarding-takeover-v2-home-nudge', {
		start: () => setIsHomeNudgeHidden(false),
		stop: () => setIsHomeNudgeHidden(true),
	});

	const handleDismiss = () => {
		setIsHomeNudgeHidden(true);
		void homeNudgeChoreographer.stopMessage();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'homeOnboardingTakeoverV2HomeNudgeDismiss',
				source: 'onboarding',
			},
		}).fire();
	};

	const handleGoHome = () => {
		push(HOME.toUrl());
		void homeNudgeChoreographer.stopMessage();
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'homeOnboardingTakeoverV2HomeNudgeGoHome',
				source: 'onboarding',
			},
		}).fire();
	};

	useEffect(() => {
		if (isEligibleForHomeNudge && isHomeNudgeHidden && !wasEligibleForOnboardingNudgeRef.current) {
			void setOnboardingState({
				key: HOME_ONBOARDING_TAKEOVER_V2_ONBOARDING_STATE_KEYS.hasSeenHomeNudge,
				value: 'true',
			});
			void homeNudgeChoreographer.startMessage();
			expandSideNav();
			wasEligibleForOnboardingNudgeRef.current = true;
		}
	}, [
		isEligibleForHomeNudge,
		isHomeNudgeHidden,
		setOnboardingState,
		homeNudgeChoreographer,
		expandSideNav,
	]);

	const isNudgeHidden =
		!isHomeNudgeHidden && (wasEligibleForOnboardingNudgeRef.current || isEligibleForHomeNudge);

	return (
		<OnboardingNudge
			hidden={!isNudgeHidden || isOnHome}
			setHidden={() => homeNudgeChoreographer.stopMessage()}
			position={position}
			wrapper={NudgeFixer}
			actions={[
				{
					onClick: handleGoHome,
					text: formatMessage(i18n.goHomeCTA),
				},
				{
					onClick: handleDismiss,
					text: formatMessage(i18n.dismissCTA),
				},
			]}
			actionSubjectId="home-onboarding-takeover-v2-home-nav-item"
			key="home-onboarding-takeover-v2-home-nav-item"
			width={275}
			source="onboarding"
			content={formatMessage(i18n.description)}
			renderChildrenWhileLoading
		>
			{children}
			{error && <ErrorDisplay error={error} />}
		</OnboardingNudge>
	);
};
