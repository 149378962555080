import { UNSAFE_noExposureExp } from '@confluence/feature-experiments';
import { isMobileUserAgent, shouldShowMobileWeb } from '@confluence/mobile-detection';

import { useSessionData, AccessStatus } from '@confluence/session-data';
import { Cohorts } from '@confluence/onboarding-helpers/entry-points/constants/cohorts';
import { ONBOARDING_EXPERIENCES } from '@confluence/onboarding-helpers/entry-points/constants/onboarding-state-constants';
import { useIsXflowUser } from '@confluence/onboarding-helpers/entry-points/hooks/useIsXflowUser';

import {
	useGetOnboardingState,
	deserializeState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';

const CC_GETTING_STARTED_TOUR_XFLOW = 'cc_getting_started_tour_xflow';
const CC_GETTING_STARTED_TOUR_LAND_OTHERS = 'cc_getting_started_tour';

/**
 * Eligiblity check
 * 1. Desktop only
 * 2. user is new
 * 3. user is a licensed admin
 * 4. user is a founding admin

 *
 * Results
 * 1. isEligibleForSpaceGettingStartedTour = user meets Eligiblity check and is not in "NOT_ENROLLED"
 * 2. isSpaceGettingStartedTourExperimentCohort = user meets Eligiblity check and is in "EXPERIMENT"
 *
 */
export const useIsSpaceGettingStartedTourEligible = () => {
	const { accessStatus, isLoggedIn } = useSessionData();

	const [xflowExperiment] = UNSAFE_noExposureExp(CC_GETTING_STARTED_TOUR_XFLOW);
	const cohortXflow = xflowExperiment.get<'experiment' | 'not-enrolled' | 'control'>(
		'cohort',
		'not-enrolled',
	);

	const [landExperiment] = UNSAFE_noExposureExp(CC_GETTING_STARTED_TOUR_LAND_OTHERS);
	const cohortLandAndOther = landExperiment.get<'experiment' | 'not-enrolled' | 'control'>(
		'cohort',
		'not-enrolled',
	);

	//1. Experiment eligbile for desktop only
	const isDesktopUser = !(isMobileUserAgent() || shouldShowMobileWeb());

	//2. user is new - For experiment add FG filter on activation date
	// [TODO] Productionize ticket https://hello.jira.atlassian.cloud/browse/CONFONBO-4575

	//3. user is a licensed admin
	const isLicensedAdmin = isLoggedIn && accessStatus === AccessStatus.LICENSED_ADMIN_ACCESS;

	/**
	 * skip = true when both land/xflow experiment flag is not enrolled
	 */
	const isFFNotEnrolled = (currentFFValue: string) => currentFFValue === Cohorts.NOT_ENROLLED;
	const skipConditionsForQueries =
		!isDesktopUser || !isLicensedAdmin || [cohortXflow, cohortLandAndOther].every(isFFNotEnrolled);

	//4. user is a founding admin
	const {
		data: onboardingStateData,
		loading: onboardingStateLoading,
		error: onboardingStateError,
	} = useGetOnboardingState(
		Object.values({
			...ONBOARDING_EXPERIENCES,
		}),
		skipConditionsForQueries,
	);

	const { isFoundingAdmin } = deserializeState(onboardingStateData);

	//5. land users belong to land experiment; xflow users belong to crossflow experiment
	const {
		isXflowUser,
		isLoading: isXflowUserLoading,
		error: isXflowUserError,
	} = useIsXflowUser({
		skip: skipConditionsForQueries || !isFoundingAdmin,
	});

	//  Check overall eligibility criteria
	const meetsExperimentTargetingCriteria = isFoundingAdmin && isDesktopUser;

	//---------------------------------
	// To know final result, all query has to finished
	const hasAllQueryFinished =
		!onboardingStateLoading && !onboardingStateError && !isXflowUserLoading && !isXflowUserError;

	return {
		isLandAndOtherEligibleForSpaceGettingStartedTour:
			cohortLandAndOther !== Cohorts.NOT_ENROLLED &&
			!isXflowUser &&
			hasAllQueryFinished &&
			meetsExperimentTargetingCriteria,
		isXflowEligibleForSpaceGettingStartedTour:
			cohortXflow !== Cohorts.NOT_ENROLLED &&
			isXflowUser &&
			hasAllQueryFinished &&
			meetsExperimentTargetingCriteria,
		isSpaceGettingStartedTourExperimentCohort:
			((cohortLandAndOther === Cohorts.EXPERIMENT && !isXflowUser) ||
				(cohortXflow === Cohorts.EXPERIMENT && isXflowUser)) &&
			hasAllQueryFinished &&
			meetsExperimentTargetingCriteria,

		loading: onboardingStateLoading || isXflowUserLoading,
		error: onboardingStateError || isXflowUserError,
	};
};
